<template>
  <v-list expand nav v-bind="$attrs" v-on="$listeners">
    <template v-for="(item, i) in items">
      <default-list-group v-if="item.items" :key="`group-${i}`" :item="item" />

      <default-list-item v-else :key="`item-${i}`" :item="item" />     
    </template>
    <!-- <v-list-item
      class="cursor"
      :class="['py-1']"
      v-bind="$attrs"
      v-on="$listeners"
      @click="logout"
    >
      <v-list-item-icon class="my-2 align-self-center">
        <v-img class="custom-icon" :src="require('@/assets/bar/logout.svg')" />
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title class="drawer-text" v-text="$t('bar.lbl.logout')" />
      </v-list-item-content>
    </v-list-item> -->
  </v-list>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'DefaultList',

  components: {
    DefaultListGroup: () => import('./ListGroup'),
    DefaultListItem: () => import('./ListItem'),
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapActions(['logout_act']),
    logout() {
      this.logout_act().then(() => {
        this.navigateReplace('Login');
        this.$store.commit('SET_DRAWER', false);
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.v-list {
  .v-list-item::v-deep {
    .v-list-item__icon {
      margin-right: 15px;
      min-width: 20px;
    }
  }
  .v-list-group::v-deep {
    .v-list-item__icon {
      margin-right: 15px;
      min-width: 20px;
    }
  }
}
.custom-icon {
  width: 25px;
  font-size: 18px;
  margin-right: 8px;
}
.custom-v-icon {
  font-size: 30px;
  margin-left: -6px;
  margin-right: 8px;
}
.drawer-text {
  color: var(--primary-color);
  font-size: 16px;
}
</style>
